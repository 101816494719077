import { Card, Container, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import '../App.css';
import useStyles from '../configandstores/styles';
import PostQuizGrid from './PostQuizGrid'
import button from '../images/button.png'
import { Link } from "react-router-dom";
import categoryDefinition from '../data/categoryDefinition'
import { getSpotMathQuestions} from './GetSpotMathQuestions';
import { getSpotFlagQuestions} from './GetSpotFlagQuestions';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../configandstores/firebase";
import {  setDoc,doc,updateDoc,collection, getDocs, increment, query, where} from "firebase/firestore";



export default function NewQuestions(){
	const [userNew, loading, error] = useAuthState(auth);
	const classes=useStyles();
    const user = useSelector((state) => state.users.value);
	const [isLoading, setLoading] = useState(true);
    const [questions, setQuestions] = useState('')
	const url=("https://mern-beginners2.herokuapp.com/newquestion?category=".concat(user.category).concat("&gradeLevel=Grade3 (7-8 yrs)"))
	let [initTime,setInitTime]=useState(0);
	const containerRef = React.useRef(null);
    useEffect(() => {
		async function getRecords() {
			const requestOptions = {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
				// body: jsonQry
			};
			const response = await fetch(url,requestOptions);   
			if (!response.ok) {
				const message = `An error occurred: ${response.statusText}`;
				window.alert(message);
				return;
			}     
			const records = await response.json();
			setQuestions(records);
			setLoading(false);
			setInitTime((new Date).getTime())
		}
		if (user.category.split(":")[0]=='Math Fun'){
			var category_def=categoryDefinition.filter(name => name.label===user.category)[0]
			const questionArray = getSpotMathQuestions(category_def)
			setQuestions(questionArray)
			setLoading(false);
			setInitTime((new Date).getTime())
		} else if (user.category.split(":")[0]=='General'){
			var category_def=categoryDefinition.filter(name => name.label===user.category)[0]
			const questionArray = getSpotFlagQuestions(category_def)
			setQuestions(questionArray)
			setLoading(false);
			setInitTime((new Date).getTime())
		} else {
			getRecords();    
		}
		
		return;
    }, [questions.length]);

		// These should not be a state variable because they do not rerender the UI
	const [times,setTimes] = useState([0]);
	const [lastTime,setLastTime]=useState((new Date).getTime())
	const [checked, setChecked] = useState(true);
	const [selectedOption,setSelectedOption] = useState([]);
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);
	function updateTransition(){
		setChecked(true)
	}
	const handleAnswerOptionClick = (isCorrect,currentQuestion,index) => {
		///////////////////////////
		var thisTime=(new Date).getTime()
		const updatedtimes = [...times, thisTime-lastTime];
		setLastTime(thisTime)
		setTimes(updatedtimes)
		const updatedOption = [...selectedOption,index]
		setSelectedOption(updatedOption)
		///////////////////
		setChecked(false)
		setTimeout(updateTransition,400)
		if (isCorrect) {
			setScore(score + 1);
		}
		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < questions.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			pushScoreToDb()

			setShowScore(true);
		}
    }  
	
	if (isLoading || questions=='') {
		return <div className="App">Loading...</div>;
	  }

	function pushScoreToDb(){
		const totalTime=times.reduce((a,b)=>a+b,0)
		// alert(times)
		// alert(totalTime)
		const userHistoryDataRef = doc(db, "userHistoryData", userNew.uid);
		// const updateString= user.category + "."+user.gradeLevel +".attempts" ;
		const docData = {
			visitCount: increment(1),
			profileByCategories: {
				[user.category]: {
					profileByGradeLevel:{
					[user.gradeLevel]: {
						attempts: increment(1),
						totalCorrect: increment(score),
						totalQuestions: increment(questions.length),
						totalTimeSeconds: increment(totalTime/1000)
						}
					}
				}
			}
		};

		setDoc(userHistoryDataRef, docData, { merge: true }).then(function(result) {
			console.log(result); // "Promise resolved successfully"
		 }, err => {
			console.log(err); // Error: "Promise rejected"
		 });
		return "UpdatedInDb"		
	}

	

    return (	
        <Container >
		<Grid container spacing={4}>
			
              <Grid item xs={12} sm={8} md={6}>
		{showScore ? (
				<>
				<Card align='center' className={classes.questioncard}>
				<Link align='center' to="/Home"> Back to Home </Link>
					You scored {score} out of {questions.length}
				</Card>	
				<PostQuizGrid questions={questions} times={times} selectedOption={selectedOption} />
				</>
			) : (
			<>
				<div className='question-section'>
					<Typography variant="h6">
						<span>Question {currentQuestion + 1}</span>/{questions.length}
					</Typography>
					{questions[currentQuestion].question.questionPicUrl<1000?<></>:<a><img width='100%' src={questions[currentQuestion].question.questionPicUrl}/></a>}
					<Typography variant="h6" className='question-text'>{questions[currentQuestion].question.questionText}</Typography>
				</div>
				<div className='answer-section'>
						<Slide Slide direction="right" container={containerRef.current} in={checked} timeout={400}>
					<ul className='nobull'>
					{questions[currentQuestion].answerOptions.map((answerOption, index) => (
								<li className="listClass">
									<img width='100%' src={answerOption.answerPicUrl<1000?button:answerOption.answerPicUrl} onClick={()=>handleAnswerOptionClick(answerOption.isCorrect,currentQuestion,index)} />
									<Typography variant="h6">{answerOption.answerText}</Typography> 
								</li>
						))}
					</ul>
						</Slide>
				</div>
				</>	
			)}
			</Grid>
			</Grid>
			
        </Container>
    );
  }