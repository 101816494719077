import { PieChart, Pie, Tooltip, BarChart, XAxis, YAxis, Legend, CartesianGrid, Bar,Cell} from "recharts";
import React, { useEffect, useState } from 'react';

const COLORS = ["#1f77b4", "#ff7f0e", "#2ca02c","#8884d8","#E666B3", '#33991A', '#CC9999', '#B3B31A', '#00E680', '#4D8066', '#809980', '#E6FF80', '#1AFF33']

const PercentTime = ({userHistoryData}) => {
  const [data1,setData1]=useState(false);
  function getValue(){
    var newData=[]
    for (const [key,value] of Object.entries(userHistoryData.profileByCategories)){
      newData.push({
        "category":key,
        "time":value.profileByGradeLevel["Grade3 (7-8 yrs)"].totalTimeSeconds
      })
    }
    setData1(newData)
  }
  const [isLoading, setIsLoading]= useState(true)
  useEffect(() => {
    if (isLoading){
      getValue()
    }
    if (data1 instanceof Object){
      setIsLoading(false); 
    }
  }, [data1]);

  if (isLoading) {
    return <>...</>
  }
  return (
    <div style={{ textAlign: "Left" }}>
      <h4 style={{ marginLeft: 60, fontWeight: 400, color: "#555" }}>Your top categories by time</h4>
      <div className="App">
        <PieChart width={400} height={300} margin={{ top: 0, left: 0, right: 0, bottom: 0 }} >
          <Pie
          dataKey="time"
          data={data1}
          cx={140}
          cy={200}
          innerRadius={40}
          outerRadius={80}
          nameKey="category"
          fill="#82ca9d"
          >
            {data1.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>
    </div>
  );
};

export default PercentTime;