import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import useStyles from '../configandstores/styles';
import ProfileTiles from './ProfileTiles';

export default function Home() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
        <Typography component="h1" variant="h3" align="center" color="textPrimary" >
              Quiz time
        </Typography>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          <ProfileTiles filterValue="Math Fun" />
          <ProfileTiles filterValue="General" />
          {/* <ProfileTiles filterValue="Verbal" />
          <ProfileTiles filterValue="Non-Verbal" />
          <ProfileTiles filterValue="Quantitative" /> */}
        </Container>
      </main>
    </React.Fragment>
  );
}