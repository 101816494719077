import { Grid, Input, Typography } from '@material-ui/core';
import React, { useState, useReducer } from 'react';
import '../App.css';
import useStyles from '../configandstores/styles';

export default function QuestionGrid({i,myQTextArray,myQPicArray}) {
    const classes=useStyles();  
    const [profileImg,setProfileImg] = useState('https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png')
    const reducer = (state, action) => {
      switch(action.type){
        case 'increment':
          state[i]=action.value
          return state
        default:
          return state
      }
    }
    const [newState,dispatch]= useReducer(reducer, myQPicArray)
    return (
        <div className={classes.question}>
        <Grid container spacing={5} justifyContent="center">
          <Grid item>
            <Typography >Question</Typography>
          </Grid>
          <Grid item>  
            <Typography>Upload Question Image</Typography> 
            <div>
            <input 
            type="file"
            onChange={(event)=>{
              dispatch(
                {type:'increment',
                  value: event.target.files[0]}
                );
                myQPicArray = newState
                const reader = new FileReader();
                reader.onload=()=>{
                  if (reader.readyState===2){
                    setProfileImg(reader.result)
                  }
                }
                reader.readAsDataURL(event.target.files[0])
            }}
            />  
            </div>
            <img width='10%' src={profileImg} alt="" id="img" className="img" />
          </Grid>
          <Grid item>
          <Input type='text' multiline placeholder='Enter Question text' 
          onChange={
            (event)=>{
              myQTextArray[i]=(event.target.value);
            }
          } 
          />
          </Grid>
        </Grid>
        </div>
    )
}

