import { createSlice } from "@reduxjs/toolkit";

import { UsersData } from "../data/ReduxFakeData";

export const userSlice = createSlice({
  name: "users",
  initialState: { value: UsersData },
  reducers: {
    addUser: (state, action) => {
      state.value.push(action.payload);
    },

    setCategory: (state, action) => {
      let user=state.value;
      user.category = action.payload.category;
    },

    setGradeLevel: (state, action) => {
      let user=state.value;
      user.gradeLevel = action.payload.gradeLevel;
    },

    updateUsername: (state, action) => {
        let user=state.value;
        user.username = action.payload.username;
    },
  },
});

export const { addUser, deleteUser, updateUsername, setCategory,setGradeLevel } = userSlice.actions;
export default userSlice.reducer;
