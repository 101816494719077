import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import "firebase/firestore";
import {  collection, getDocs, query, where} from "firebase/firestore";
import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../configandstores/firebase";
import useStyles from '../configandstores/styles';
import fakeProfileData from '../data/fakeProfileData';
import PercentTime from './Charts/PercentTime';
import { doc, getDoc } from "firebase/firestore";

export default function Profile(){
  const [userNew, loading, error] = useAuthState(auth);
  const [userHistoryData, setUserHistoryData]= useState(false)
  const [userPastCategories, setUserPastCategories]= useState(false)
  const [isLoading, setIsLoading]= useState(true)
  const [dataForCategory, setDataForCategory] = useState('');
  const [count, setCount]=useState(0)
  const [category,setCategory] = useState('')
  const classes = useStyles();
  function getValue(){
    const docRef = doc(db, "userHistoryData",  userNew.uid);
    const docSnap = getDoc(docRef).then(function(result) {
      // setUserHistoryData(result.data()); // "Promise resolved successfully"
      setUserHistoryData(result.data())
      }, err => {
      console.log(err); // Error: "Promise rejected"
      }); 
  }

  useEffect(() => {
    if (isLoading){
      getValue()
    }
    if (userHistoryData){
      var temp = 0
      for (const [key,value] of Object.entries(userHistoryData.profileByCategories)){
        //console.log(value.profileByGradeLevel["Grade3 (7-8 yrs)"].attempts)
        temp=temp+value.profileByGradeLevel["Grade3 (7-8 yrs)"].attempts     
      }
      setCount(temp)
      setUserPastCategories(Object.keys(userHistoryData.profileByCategories));
      setIsLoading(false); 
    }
  }, [userHistoryData]);

  useEffect(()=>{
    if (!category==''){
      setDataForCategory(userHistoryData.profileByCategories[category].profileByGradeLevel["Grade3 (7-8 yrs)"])
    }
  },[category,dataForCategory])  


  function updateDataForCategory(e){
    setCategory(e)
  }

  useEffect(()=>{
  },[setDataForCategory])  

  if (isLoading){
    return <>Loading...</>
  }
  return (
    <>
      <h2 style={{ marginLeft: 60, fontWeight: 400, color: "#555" }}>
        Your activity: {count} Assessments
      </h2>
      {/* <h4 style={{ marginLeft: 60, fontWeight: 400, color: "#555" }}>
        Last 30 days activity: 20 visits
      </h4> */}
      {/* {console.log("Yo")}
      {console.log(db)} */}
    
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardMedia>
              <PercentTime userHistoryData={userHistoryData}/>
            </CardMedia>
          </Card>
        </Grid>
      </Grid>
      <h2 style={{ marginLeft: 60, fontWeight: 400, color: "#555" }}>
        Select category to analyze 
      </h2>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardMedia>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                  labelId="demo-simple-select-helper-label"
                  defaultValue = ""
                  id="demo-simple-select-helper"
                  value={category}
                  onChange={(event)=>{updateDataForCategory(event.target.value)}}
                >
                  {userPastCategories.map((data) => (
                    <MenuItem key={data} value={data}>
                      {data}
                    </MenuItem>
                  ))}
                </Select>
            </FormControl>
          {(dataForCategory==='')?
            (<></>):
            (<>
            <h4 style={{ marginLeft: 60, fontWeight: 400, color: "#555" }}>
              # Attempts: {dataForCategory.attempts}
            </h4>
            <h4 style={{ marginLeft: 60, fontWeight: 400, color: "#555" }}>
              All time accuracy: {Math.round(dataForCategory.totalCorrect/dataForCategory.totalQuestions*100)} percent
            </h4>
            <h4 style={{ marginLeft: 60, fontWeight: 400, color: "#555" }}>
              Total time spent: {Math.round(dataForCategory.totalTimeSeconds/60)} minutes and {Math.round(dataForCategory.totalTimeSeconds%60)} seconds
            </h4>
            </>
          )}
          
            </CardMedia>
          </Card>
        </Grid>
      </Grid>

      <h2 style={{ marginLeft: 60, fontWeight: 400, color: "#555" }}>
        Awards and Trophies
      </h2>
      <Grid container spacing={5} align="center">
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <img  width='30%' src={require('../images/Trophies/Gold Cup.png')} />
            <div class='text-on-image'>
             <h3> AccurateDragon </h3>
             <p> For high precision strikes </p>
          </div>          
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
