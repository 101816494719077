import { Box, Button, Card, Container, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import '../App.css';
import useStyles from '../configandstores/styles';

export default function ShowQuestions(){
	const classes=useStyles();
    const [records, setRecords] = useState([]);
    // Setting this state to non-empty state seems critical. if I set to empty state, the array doesn't load
    const [questions, setQuestions] = useState([
		{
			questionText: '',
			answerOptions: [
				{ answerText: '', isCorrect: false },
			],
		},
	]);
	// https://mern-beginners2.herokuapp.com/question
	//http://localhost:3001/question`
    useEffect(() => {
        async function getRecords() {
        const response = await fetch(`https://mern-beginners2.herokuapp.com/question`);   
        if (!response.ok) {
            const message = `An error occurred: ${response.statusText}`;
            window.alert(message);
            return;
        }     
        const records = await response.json();
        setQuestions(records);
        }  
        getRecords();    
        return;
    }, [records.length]);

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);

	const handleAnswerOptionClick = (isCorrect) => {
		if (isCorrect) {
			setScore(score + 1);
		}

		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < questions.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			setShowScore(true);
		}
    }   
    return (
        <Container className={classes.container} >
        {showScore ? (
				<Card align='center' className={classes.questioncard} >
					You scored {score} out of {questions.length}
				</Card>
			) : (
				<Card align='center' className={classes.questioncard} >
					<div className='question-section'>
						<Typography variant="h6">
							<span>Question {currentQuestion + 1}</span>/{questions.length}
						</Typography>
						<Typography variant="h6" className='question-text'>{questions[currentQuestion].questionText}</Typography>
					</div>
					<div className='answer-section'>
						{questions[currentQuestion].answerOptions.map((answerOption, index) => (
							<Box>
							<Paper>
								<Button  align='center' variant="contained" color="primary" onClick={()=>handleAnswerOptionClick(answerOption.isCorrect)}>{answerOption.answerText}</Button>
								<Box>-</Box>
							</Paper>
							</Box>
						))}
					</div>
				</Card>
			)}
        </Container>
    );
  }