import { Box, Button, Container, CssBaseline, Grid, Input, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import '../App.css';
import useStyles from '../configandstores/styles';
import OptionCard from './OptionCard';
import './QuestionGrid';
import QuestionGrid from './QuestionGrid';
import Tags from './Tags';

export const UserContext = React.createContext();
//Borrowed heavily for design from: https://www.youtube.com/watch?v=Xoz31I1FuiY
// for cloudinary upload from: https://www.youtube.com/watch?v=Y-VgaRwWS3o 

function Creator() {
  const classes=useStyles();

  //Define the number of answer choices
  const [optionCount, setOptionCount]=useState(4);
  // Added 1 for the question to be part of array for mongodb upload
  const arraySize=optionCount+1;
  const [category,setCategory] = useState('')
  const [gradeLevel,setGradeLevel] = useState('')
  const [customTag,setCustomTag] = useState('')

  const [tags,setTags] = useState('')
  const [suggestedReading,setSuggestedReading] = useState('')
  const [correctOption,setCorrectOption] = useState('')
  const [myQTextArray, setMyQTextArray] = useState(Array.from(Array(arraySize).keys()))
  const [myQPicArray, setMyQPicArray] = useState(Array.from(Array(arraySize).keys()))
  const [myAnsTextArray, setMyAnsTextArray] = useState(Array.from(Array(arraySize).keys()))
  const [myAnsPicArray, setMyAnsPicArray] = useState(Array.from(Array(arraySize).keys()))
  const [myAnsExplanationArray, setMyAnsExplanationArray] = useState(Array.from(Array(arraySize).keys()))
  const [mergedTextArray, setMergedTextArray] = useState(Array.from(Array(arraySize).keys()))
  const [mergedPicArray, setMergedPicArray] = useState(Array.from(Array(arraySize).keys()))
  const [mergedExplanationArray, setMergedExplanationArray] = useState(Array.from(Array(arraySize).keys()))

  const [picUploadDetails, setPicUploadDetails] = useState(Array.from(Array(arraySize).keys()))

  const uploadPacket = (files)=> {
    for (let i=1;i<=optionCount;i++){
      mergedPicArray[i]=myAnsPicArray[i]
    }
    mergedPicArray[0]=myQPicArray[0];
    //Upload each picture to cloudinary
    // upload question, question text, option image cloudinary url, option text to mongodb
    console.log("mergedPicArray is" + mergedPicArray)
    console.log(myQPicArray[0] instanceof File)
    console.log(myQPicArray[1] instanceof File)
    async function uploadPicArray(){
      mergedPicArray.map((pic,i)=>{
        const uploadSinglePicToCloudinary = async () => {
          if (pic instanceof File){
            const formData = new FormData()
            formData.append("file",pic)
            formData.append("upload_preset","htpp0z6y")
            const rawResponse = await fetch('https://api.cloudinary.com/v1_1/dtqsphq5g/image/upload',{method:'POST',body:formData,})
            picUploadDetails[i]=rawResponse.url;
            }
          }
          uploadSinglePicToCloudinary()
      })
    }
    uploadPicArray()
  }

    const createUserPacket = async (jsonFinalPacketString) => {

      for (let i=1;i<=optionCount;i++){
        mergedPicArray[i]=myAnsPicArray[i]
      }
      mergedPicArray[0]=myQPicArray[0];
      for (let i=1;i<=optionCount;i++){
        mergedTextArray[i]=myAnsTextArray[i]
      }
      mergedTextArray[0]=myQTextArray[0];
      for (let i=1;i<=optionCount;i++){
        mergedExplanationArray[i]=myAnsExplanationArray[i]
      }
      var finalPacket=createQuestionJsonPacket(picUploadDetails,mergedTextArray,correctOption,category,gradeLevel,customTag,suggestedReading)
      var jsonFinalPacketString = JSON.stringify(finalPacket);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: jsonFinalPacketString
    };
    //http://localhost:3001/addquestionpacket
      // https://mern-beginners2.herokuapp.com/addquestionpacket
      const rawResponse = fetch('https://mern-beginners2.herokuapp.com/addquestionpacket',requestOptions).then(res=>{}).catch(error=>console.log("error is "+error.response))
      const content = await rawResponse;
    };

    function createQuestionJsonPacket(picUploadDetails,mergedTextArray,correctOption,category,gradeLevel,customTag,suggestedReading) {
        var question = new Object();
        question.questionText=mergedTextArray[0];
        question.questionPicUrl=picUploadDetails[0];
        var answerOptions= new Object();
        for (let i=0;i<optionCount;i++){
          var option = new Object();
          option.answerText = mergedTextArray[i+1];
          option.answerPicUrl = picUploadDetails[i+1];
          option.isCorrect = (correctOption==i+1);
          option.choiceExplanation = "";
          answerOptions[i]=option;
        }

        var finalPacket = new Object();
        finalPacket.question = question;
        finalPacket.answerOptions = answerOptions;
        // finalPacket.tags = tags;
        finalPacket.category = category;
        finalPacket.gradeLevel = gradeLevel;
        finalPacket.customTag = customTag;
        finalPacket.suggestedReading = suggestedReading;  
        return finalPacket;     
    }

  return (
    <>
    <CssBaseline />
    <main>
      <div className={classes.container}>
        <Container maxWidth="sm">
          <Typography variant="h2" align="center" color="textPrimary" gutterBottom>
            Instructions
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" paragraph>
          You are awesome! Thank you for making testing affordable. As a first level content creator, please provide each question choice with corresponding pictures (preferably screenshot of designs made on computer). 
            Please also make sure to add comma separated &lt;valid&gt; tags for the question e.g. "math", "Grade-3","pattern". For comprehensive list of valid tags, check here
          </Typography>
          <QuestionGrid i={0} myQTextArray={myQTextArray} setMyQTextArray={setMyQTextArray} myQPicArray={myQPicArray} setMyQPicArray={setMyQPicArray} />
        </Container>
      </div>
      
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={4}>     
        <OptionCard i={1} myAnsTextArray={myAnsTextArray} setMyAnsTextArray={setMyAnsTextArray} myAnsPicArray={myAnsPicArray} setMyAnsPicArray={setMyAnsPicArray} myAnsExplanationArray={myAnsExplanationArray} setMyAnsExplanationArray={setMyAnsExplanationArray} />  
        <OptionCard i={2} myAnsTextArray={myAnsTextArray} setMyAnsTextArray={setMyAnsTextArray} myAnsPicArray={myAnsPicArray} setMyAnsPicArray={setMyAnsPicArray} myAnsExplanationArray={myAnsExplanationArray} setMyAnsExplanationArray={setMyAnsExplanationArray} />  
        <OptionCard i={3} myAnsTextArray={myAnsTextArray} setMyAnsTextArray={setMyAnsTextArray} myAnsPicArray={myAnsPicArray} setMyAnsPicArray={setMyAnsPicArray} myAnsExplanationArray={myAnsExplanationArray} setMyAnsExplanationArray={setMyAnsExplanationArray} />  
        <OptionCard i={4} myAnsTextArray={myAnsTextArray} setMyAnsTextArray={setMyAnsTextArray} myAnsPicArray={myAnsPicArray} setMyAnsPicArray={setMyAnsPicArray} myAnsExplanationArray={myAnsExplanationArray} setMyAnsExplanationArray={setMyAnsExplanationArray} />        
        </Grid>
        <div align="center" >
          <Box display="flex" >
            <Typography className={classes.cardContent}>Please choose the correct option number:-</Typography>
            <Input className={classes.cardContent} type='number' inputProps={{ min: 1, max: 4 }} placeholder='Correct option' onChange={(event)=>{setCorrectOption(event.target.value);}} />
          </Box>
          <Tags i={4} category={category} setCategory={setCategory} gradeLevel={gradeLevel} setGradeLevel={setGradeLevel} setCustomTag={setCustomTag}/>        
          <Box display="flex" >
          <Typography className={classes.cardContent}>Please enter suggested reading for this question:-</Typography>
          <Input className={classes.cardContent} type='text' placeholder='e.g. Khan Academy Section <XYZ>' onChange={(event)=>{setSuggestedReading(event.target.value);}} />
          </Box>
          <Box className={classes.question}></Box>
          <Box >
            <Button className={classes.question} align='center' variant="contained" color="primary" onClick={()=>uploadPacket()}>Create Packet</Button>
            <Button className={classes.question} align='center' variant="contained" color="primary" onClick={()=>createUserPacket()}>Upload Packet</Button>
          </Box>
        </div>     
      </Container>

    </main>
    <footer align="center">

    </footer>
    </>
  );
}

export default Creator;
