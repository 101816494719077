const categoryData =
[
// {id: 1, label: "Verbal:Reading Comprehension",},
// {id: 2, label: "Verbal:Sentence Completion",},
// {id: 3, label: "Verbal:Picture/Verbal Classification",},
{id: 4, label: "Verbal:Picture/Verbal Analogies",img:"verbal"},
// {id: 5, label: "Non-Verbal:Paper Folding",},
{id: 6, label: "Non-Verbal:Figure Classification",img:"nonverbal"},
// {id: 7, label: "Non-Verbal:Similar Words",},
{id: 8, label: "Quantitative:Number Series",img:"quantitative"},
// {id: 9, label: "Quantitative:Number Puzzles",},
// {id: 10, label: "Quantitative:Number Analogies",},
{id: 8, label: "Math Fun:2 Digit Math Addition",img:"plus"},
{id: 9,label :"Math Fun:2 Digit Math Subtraction",img:"minus"},
{id: 10, label :"Math Fun:1 Digit Math Multiplication",img:"multiply"},
{id: 10, label :"General:Flags of country",img:"flags"},
]; 

export default categoryData;