const countryData =
[
{id: "ISO 3166-2:AL", countryName: "Albania", countryCapital: "Tirana",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Flag_of_Albania.svg/320px-Flag_of_Albania.svg.png'},
{id: "ISO 3166-2:CA", countryName: "Canada", countryCapital: "Ottawa",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Canada_%28Pantone%29.svg/320px-Flag_of_Canada_%28Pantone%29.svg.png'},
{id: "ISO 3166-2:IN", countryName: "India", countryCapital: "New Delhi",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/320px-Flag_of_India.svg.png'},
{id: "ISO 3166-2:KR", countryName: "South Korea", countryCapital: "Seoul",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Flag_of_South_Korea.svg/320px-Flag_of_South_Korea.svg.png'},
{id: "ISO 3166-2:US", countryName: "United States of America", countryCapital: "Washington, D.C.",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/320px-Flag_of_the_United_States.svg.png'},
{id: "ISO 3166-2:AF", countryName: "Afghanistan", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/Flag_of_Afghanistan_%282013%E2%80%932021%29.svg/320px-Flag_of_Afghanistan_%282013%E2%80%932021%29.svg.png'},
{id: "ISO 3166-2:AL", countryName: "Albania", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Flag_of_Albania.svg/320px-Flag_of_Albania.svg.png'},
{id: "ISO 3166-2:DZ", countryName: "Algeria", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Flag_of_Algeria.svg/320px-Flag_of_Algeria.svg.png'},
{id: "ISO 3166-2:AS", countryName: "American Samoa", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Flag_of_American_Samoa.svg/320px-Flag_of_American_Samoa.svg.png'},
{id: "ISO 3166-2:AD", countryName: "Andorra", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Flag_of_Andorra.svg/320px-Flag_of_Andorra.svg.png'},
{id: "ISO 3166-2:AO", countryName: "Angola", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Flag_of_Angola.svg/320px-Flag_of_Angola.svg.png'},
{id: "ISO 3166-2:AI", countryName: "Anguilla", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Flag_of_Anguilla.svg/320px-Flag_of_Anguilla.svg.png'},
{id: "ISO 3166-2:AG", countryName: "Antigua and Barbuda", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Flag_of_Antigua_and_Barbuda.svg/320px-Flag_of_Antigua_and_Barbuda.svg.png'},
{id: "ISO 3166-2:AR", countryName: "Argentina", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Flag_of_Argentina.svg/320px-Flag_of_Argentina.svg.png'},
{id: "ISO 3166-2:AM", countryName: "Armenia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Flag_of_Armenia.svg/320px-Flag_of_Armenia.svg.png'},
{id: "ISO 3166-2:AW", countryName: "Aruba", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Flag_of_Aruba.svg/320px-Flag_of_Aruba.svg.png'},
{id: "ISO 3166-2:AT", countryName: "Austria", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_Austria.svg/320px-Flag_of_Austria.svg.png'},
{id: "ISO 3166-2:AZ", countryName: "Azerbaijan", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/dd/Flag_of_Azerbaijan.svg/320px-Flag_of_Azerbaijan.svg.png'},
{id: "ISO 3166-2:BS", countryName: "Bahamas (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Flag_of_the_Bahamas.svg/320px-Flag_of_the_Bahamas.svg.png'},
{id: "ISO 3166-2:BH", countryName: "Bahrain", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Flag_of_Bahrain.svg/320px-Flag_of_Bahrain.svg.png'},
{id: "ISO 3166-2:BD", countryName: "Bangladesh", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Flag_of_Bangladesh.svg/320px-Flag_of_Bangladesh.svg.png'},
{id: "ISO 3166-2:BB", countryName: "Barbados", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Flag_of_Barbados.svg/320px-Flag_of_Barbados.svg.png'},
{id: "ISO 3166-2:BY", countryName: "Belarus", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/Flag_of_Belarus.svg/320px-Flag_of_Belarus.svg.png'},
{id: "ISO 3166-2:BE", countryName: "Belgium", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/Flag_of_Belgium_%28civil%29.svg/320px-Flag_of_Belgium_%28civil%29.svg.png'},
{id: "ISO 3166-2:BZ", countryName: "Belize", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Flag_of_Belize.svg/320px-Flag_of_Belize.svg.png'},
{id: "ISO 3166-2:BJ", countryName: "Benin", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0a/Flag_of_Benin.svg/320px-Flag_of_Benin.svg.png'},
{id: "ISO 3166-2:BM", countryName: "Bermuda", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Flag_of_Bermuda.svg/320px-Flag_of_Bermuda.svg.png'},
{id: "ISO 3166-2:BT", countryName: "Bhutan", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Flag_of_Bhutan.svg/320px-Flag_of_Bhutan.svg.png'},
{id: "ISO 3166-2:BO", countryName: "Bolivia (Plurinational State of)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Bandera_de_Bolivia_%28Estado%29.svg/34px-Bandera_de_Bolivia_%28Estado%29.svg.png'},
{id: "ISO 3166-2:BA", countryName: "Bosnia and Herzegovina", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Flag_of_Bosnia_and_Herzegovina.svg/320px-Flag_of_Bosnia_and_Herzegovina.svg.png'},
{id: "ISO 3166-2:BW", countryName: "Botswana", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Flag_of_Botswana.svg/320px-Flag_of_Botswana.svg.png'},
{id: "ISO 3166-2:BV", countryName: "Bouvet Island", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Norway.svg/320px-Flag_of_Norway.svg.png'},
{id: "ISO 3166-2:BR", countryName: "Brazil", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/0/05/Flag_of_Brazil.svg/320px-Flag_of_Brazil.svg.png'},
{id: "ISO 3166-2:IO", countryName: "British Indian Ocean Territory (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/Flag_of_the_Commissioner_of_the_British_Indian_Ocean_Territory.svg/320px-Flag_of_the_Commissioner_of_the_British_Indian_Ocean_Territory.svg.png'},
{id: "ISO 3166-2:BG", countryName: "Bulgaria", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Bulgaria.svg/320px-Flag_of_Bulgaria.svg.png'},
{id: "ISO 3166-2:BF", countryName: "Burkina Faso", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Flag_of_Burkina_Faso.svg/320px-Flag_of_Burkina_Faso.svg.png'},
{id: "ISO 3166-2:BI", countryName: "Burundi", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/50/Flag_of_Burundi.svg/320px-Flag_of_Burundi.svg.png'},
{id: "ISO 3166-2:KH", countryName: "Cambodia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_Cambodia.svg/320px-Flag_of_Cambodia.svg.png'},
{id: "ISO 3166-2:CM", countryName: "Cameroon", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Flag_of_Cameroon.svg/320px-Flag_of_Cameroon.svg.png'},
{id: "ISO 3166-2:CA", countryName: "Canada", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Canada_%28Pantone%29.svg/320px-Flag_of_Canada_%28Pantone%29.svg.png'},
{id: "ISO 3166-2:KY", countryName: "Cayman Islands (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Flag_of_the_Cayman_Islands.svg/320px-Flag_of_the_Cayman_Islands.svg.png'},
{id: "ISO 3166-2:CF", countryName: "Central African Republic (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Flag_of_the_Central_African_Republic.svg/320px-Flag_of_the_Central_African_Republic.svg.png'},
{id: "ISO 3166-2:TD", countryName: "Chad", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Flag_of_Chad.svg/320px-Flag_of_Chad.svg.png'},
{id: "ISO 3166-2:CL", countryName: "Chile", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Flag_of_Chile.svg/320px-Flag_of_Chile.svg.png'},
{id: "ISO 3166-2:CN", countryName: "China", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/72/Flag_of_the_Republic_of_China.svg/320px-Flag_of_the_Republic_of_China.svg.png'},
{id: "ISO 3166-2:CX", countryName: "Christmas Island", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/Flag_of_Christmas_Island.svg/320px-Flag_of_Christmas_Island.svg.png'},
{id: "ISO 3166-2:CC", countryName: "Cocos (Keeling) Islands (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Flag_of_the_Cocos_%28Keeling%29_Islands.svg/320px-Flag_of_the_Cocos_%28Keeling%29_Islands.svg.png'},
{id: "ISO 3166-2:CO", countryName: "Colombia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Colombia.svg/320px-Flag_of_Colombia.svg.png'},
{id: "ISO 3166-2:KM", countryName: "Comoros (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Flag_of_the_Comoros.svg/320px-Flag_of_the_Comoros.svg.png'},
{id: "ISO 3166-2:CD", countryName: "Congo (the Democratic Republic of the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Flag_of_the_Democratic_Republic_of_the_Congo.svg/320px-Flag_of_the_Democratic_Republic_of_the_Congo.svg.png'},
{id: "ISO 3166-2:CK", countryName: "Cook Islands (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/320/Flag_of_the_Cook_Islands.svg/320px-Flag_of_the_Cook_Islands.svg.png'},
{id: "ISO 3166-2:CR", countryName: "Costa Rica", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Costa_Rica_%28state%29.svg/320px-Flag_of_Costa_Rica_%28state%29.svg.png'},
{id: "ISO 3166-2:HR", countryName: "Croatia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Flag_of_Croatia.svg/320px-Flag_of_Croatia.svg.png'},
{id: "ISO 3166-2:CU", countryName: "Cuba", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Flag_of_Cuba.svg/320px-Flag_of_Cuba.svg.png'},
{id: "ISO 3166-2:CW", countryName: "Curaçao", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/Flag_of_Cura%C3%A7ao.svg/320px-Flag_of_Cura%C3%A7ao.svg.png'},
{id: "ISO 3166-2:CY", countryName: "Cyprus", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/Flag_of_Cyprus.svg/320px-Flag_of_Cyprus.svg.png'},
{id: "ISO 3166-2:DK", countryName: "Denmark", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Flag_of_Denmark.svg/31px-Flag_of_Denmark.svg.png'},
{id: "ISO 3166-2:DJ", countryName: "Djibouti", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Flag_of_Djibouti.svg/320px-Flag_of_Djibouti.svg.png'},
{id: "ISO 3166-2:DM", countryName: "Dominica", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Flag_of_Dominica.svg/320px-Flag_of_Dominica.svg.png'},
{id: "ISO 3166-2:DO", countryName: "Dominican Republic (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Flag_of_the_Dominican_Republic.svg/320px-Flag_of_the_Dominican_Republic.svg.png'},
{id: "ISO 3166-2:EC", countryName: "Ecuador", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Flag_of_Ecuador.svg/320px-Flag_of_Ecuador.svg.png'},
{id: "ISO 3166-2:EG", countryName: "Egypt", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Flag_of_Egypt.svg/320px-Flag_of_Egypt.svg.png'},
{id: "ISO 3166-2:SV", countryName: "El Salvador", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Flag_of_El_Salvador.svg/320px-Flag_of_El_Salvador.svg.png'},
{id: "ISO 3166-2:GQ", countryName: "Equatorial Guinea", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Flag_of_Equatorial_Guinea.svg/320px-Flag_of_Equatorial_Guinea.svg.png'},
{id: "ISO 3166-2:ER", countryName: "Eritrea", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Flag_of_Eritrea.svg/320px-Flag_of_Eritrea.svg.png'},
{id: "ISO 3166-2:EE", countryName: "Estonia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Flag_of_Estonia.svg/320px-Flag_of_Estonia.svg.png'},
{id: "ISO 3166-2:ET", countryName: "Ethiopia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/71/Flag_of_Ethiopia.svg/320px-Flag_of_Ethiopia.svg.png'},
{id: "ISO 3166-2:FO", countryName: "Faroe Islands (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Flag_of_the_Faroe_Islands.svg/320px-Flag_of_the_Faroe_Islands.svg.png'},
{id: "ISO 3166-2:FJ", countryName: "Fiji", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Flag_of_Fiji.svg/320px-Flag_of_Fiji.svg.png'},
{id: "ISO 3166-2:FI", countryName: "Finland", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Finland.svg/320px-Flag_of_Finland.svg.png'},
{id: "ISO 3166-2:GF", countryName: "French Guiana", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Flag_of_French_Guiana.svg/320px-Flag_of_French_Guiana.svg.png'},
{id: "ISO 3166-2:PF", countryName: "French Polynesia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/db/Flag_of_French_Polynesia.svg/320px-Flag_of_French_Polynesia.svg.png'},
{id: "ISO 3166-2:GA", countryName: "Gabon", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Flag_of_Gabon.svg/320px-Flag_of_Gabon.svg.png'},
{id: "ISO 3166-2:GM", countryName: "Gambia (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Flag_of_The_Gambia.svg/320px-Flag_of_The_Gambia.svg.png'},
{id: "ISO 3166-2:GE", countryName: "Georgia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Flag_of_Georgia.svg/320px-Flag_of_Georgia.svg.png'},
{id: "ISO 3166-2:DE", countryName: "Germany", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/320px-Flag_of_Germany.svg.png'},
{id: "ISO 3166-2:GH", countryName: "Ghana", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Flag_of_Ghana.svg/320px-Flag_of_Ghana.svg.png'},
{id: "ISO 3166-2:GI", countryName: "Gibraltar", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Flag_of_Gibraltar.svg/320px-Flag_of_Gibraltar.svg.png'},
{id: "ISO 3166-2:GR", countryName: "Greece", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Greece.svg/320px-Flag_of_Greece.svg.png'},
{id: "ISO 3166-2:GL", countryName: "Greenland", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Flag_of_Greenland.svg/320px-Flag_of_Greenland.svg.png'},
{id: "ISO 3166-2:GD", countryName: "Grenada", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Grenada.svg/320px-Flag_of_Grenada.svg.png'},
{id: "ISO 3166-2:GP", countryName: "Guadeloupe", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Unofficial_flag_of_Guadeloupe_%28local%29.svg/320px-Unofficial_flag_of_Guadeloupe_%28local%29.svg.png'},
{id: "ISO 3166-2:GU", countryName: "Guam", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/07/Flag_of_Guam.svg/320px-Flag_of_Guam.svg.png'},
{id: "ISO 3166-2:GT", countryName: "Guatemala", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Flag_of_Guatemala.svg/320px-Flag_of_Guatemala.svg.png'},
{id: "ISO 3166-2:GG", countryName: "Guernsey", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Flag_of_Guernsey.svg/320px-Flag_of_Guernsey.svg.png'},
{id: "ISO 3166-2:GN", countryName: "Guinea", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Flag_of_Guinea.svg/320px-Flag_of_Guinea.svg.png'},
{id: "ISO 3166-2:GW", countryName: "Guinea-Bissau", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Flag_of_Guinea-Bissau.svg/320px-Flag_of_Guinea-Bissau.svg.png'},
{id: "ISO 3166-2:GY", countryName: "Guyana", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Flag_of_Guyana.svg/320px-Flag_of_Guyana.svg.png'},
{id: "ISO 3166-2:HT", countryName: "Haiti", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Flag_of_Haiti.svg/320px-Flag_of_Haiti.svg.png'},
{id: "ISO 3166-2:HM", countryName: "Heard Island and McDonald Islands", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Flag_of_Australia_%28converted%29.svg/320px-Flag_of_Australia_%28converted%29.svg.png'},
{id: "ISO 3166-2:HN", countryName: "Honduras", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Flag_of_Honduras.svg/320px-Flag_of_Honduras.svg.png'},
{id: "ISO 3166-2:HK", countryName: "Hong Kong", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Flag_of_Hong_Kong.svg/320px-Flag_of_Hong_Kong.svg.png'},
{id: "ISO 3166-2:HU", countryName: "Hungary", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Flag_of_Hungary.svg/320px-Flag_of_Hungary.svg.png'},
{id: "ISO 3166-2:IS", countryName: "Iceland", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Flag_of_Iceland.svg/32px-Flag_of_Iceland.svg.png'},
{id: "ISO 3166-2:IN", countryName: "India", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/320px-Flag_of_India.svg.png'},
{id: "ISO 3166-2:ID", countryName: "Indonesia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Flag_of_Indonesia.svg/320px-Flag_of_Indonesia.svg.png'},
{id: "ISO 3166-2:IR", countryName: "Iran (Islamic Republic of)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Flag_of_Iran.svg/320px-Flag_of_Iran.svg.png'},
{id: "ISO 3166-2:IQ", countryName: "Iraq", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Flag_of_Iraq.svg/320px-Flag_of_Iraq.svg.png'},
{id: "ISO 3166-2:IE", countryName: "Ireland", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/Flag_of_Ireland.svg/320px-Flag_of_Ireland.svg.png'},
{id: "ISO 3166-2:IM", countryName: "Isle of Man", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_the_Isle_of_Man.svg/320px-Flag_of_the_Isle_of_Man.svg.png'},
{id: "ISO 3166-2:IL", countryName: "Israel", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/Flag_of_Israel.svg/320px-Flag_of_Israel.svg.png'},
{id: "ISO 3166-2:IT", countryName: "Italy", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/0/03/Flag_of_Italy.svg/320px-Flag_of_Italy.svg.png'},
{id: "ISO 3166-2:JM", countryName: "Jamaica", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0a/Flag_of_Jamaica.svg/320px-Flag_of_Jamaica.svg.png'},
{id: "ISO 3166-2:JP", countryName: "Japan", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/9/9e/Flag_of_Japan.svg/320px-Flag_of_Japan.svg.png'},
{id: "ISO 3166-2:JE", countryName: "Jersey", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1c/Flag_of_Jersey.svg/320px-Flag_of_Jersey.svg.png'},
{id: "ISO 3166-2:JO", countryName: "Jordan", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Flag_of_Jordan.svg/320px-Flag_of_Jordan.svg.png'},
{id: "ISO 3166-2:KZ", countryName: "Kazakhstan", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Flag_of_Kazakhstan.svg/320px-Flag_of_Kazakhstan.svg.png'},
{id: "ISO 3166-2:KE", countryName: "Kenya", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Kenya.svg/320px-Flag_of_Kenya.svg.png'},
{id: "ISO 3166-2:KI", countryName: "Kiribati", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Flag_of_Kiribati.svg/320px-Flag_of_Kiribati.svg.png'},
{id: "ISO 3166-2:KW", countryName: "Kuwait", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Flag_of_Kuwait.svg/320px-Flag_of_Kuwait.svg.png'},
{id: "ISO 3166-2:KG", countryName: "Kyrgyzstan", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/Flag_of_Kyrgyzstan.svg/320px-Flag_of_Kyrgyzstan.svg.png'},
{id: "ISO 3166-2:LV", countryName: "Latvia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Flag_of_Latvia.svg/320px-Flag_of_Latvia.svg.png'},
{id: "ISO 3166-2:LB", countryName: "Lebanon", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Flag_of_Lebanon.svg/320px-Flag_of_Lebanon.svg.png'},
{id: "ISO 3166-2:LS", countryName: "Lesotho", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Flag_of_Lesotho.svg/320px-Flag_of_Lesotho.svg.png'},
{id: "ISO 3166-2:LR", countryName: "Liberia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/Flag_of_Liberia.svg/320px-Flag_of_Liberia.svg.png'},
{id: "ISO 3166-2:LY", countryName: "Libya", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Flag_of_Libya.svg/320px-Flag_of_Libya.svg.png'},
{id: "ISO 3166-2:LI", countryName: "Liechtenstein", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/Flag_of_Liechtenstein.svg/320px-Flag_of_Liechtenstein.svg.png'},
{id: "ISO 3166-2:LT", countryName: "Lithuania", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Flag_of_Lithuania.svg/320px-Flag_of_Lithuania.svg.png'},
{id: "ISO 3166-2:LU", countryName: "Luxembourg", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Flag_of_Luxembourg.svg/320px-Flag_of_Luxembourg.svg.png'},
{id: "ISO 3166-2:MG", countryName: "Madagascar", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Madagascar.svg/320px-Flag_of_Madagascar.svg.png'},
{id: "ISO 3166-2:MW", countryName: "Malawi", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Flag_of_Malawi.svg/320px-Flag_of_Malawi.svg.png'},
{id: "ISO 3166-2:MY", countryName: "Malaysia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Flag_of_Malaysia.svg/320px-Flag_of_Malaysia.svg.png'},
{id: "ISO 3166-2:MV", countryName: "Maldives", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Flag_of_Maldives.svg/320px-Flag_of_Maldives.svg.png'},
{id: "ISO 3166-2:ML", countryName: "Mali", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/Flag_of_Mali.svg/320px-Flag_of_Mali.svg.png'},
{id: "ISO 3166-2:MT", countryName: "Malta", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Flag_of_Malta.svg/320px-Flag_of_Malta.svg.png'},
{id: "ISO 3166-2:MH", countryName: "Marshall Islands (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Flag_of_the_Marshall_Islands.svg/320px-Flag_of_the_Marshall_Islands.svg.png'},
{id: "ISO 3166-2:MQ", countryName: "Martinique", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Flag_of_the_Front_National_de_Lib%C3%A9ration_de_la_Martinique.svg/320px-Flag_of_the_Front_National_de_Lib%C3%A9ration_de_la_Martinique.svg.png'},
{id: "ISO 3166-2:MR", countryName: "Mauritania", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/43/Flag_of_Mauritania.svg/320px-Flag_of_Mauritania.svg.png'},
{id: "ISO 3166-2:MU", countryName: "Mauritius", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Flag_of_Mauritius.svg/320px-Flag_of_Mauritius.svg.png'},
{id: "ISO 3166-2:YT", countryName: "Mayotte", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Flag_of_Mayotte_%28Local%29.svg/320px-Flag_of_Mayotte_%28Local%29.svg.png'},
{id: "ISO 3166-2:MX", countryName: "Mexico", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Flag_of_Mexico.svg/320px-Flag_of_Mexico.svg.png'},
{id: "ISO 3166-2:FM", countryName: "Micronesia (Federated States of)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Flag_of_the_Federated_States_of_Micronesia.svg/320px-Flag_of_the_Federated_States_of_Micronesia.svg.png'},
{id: "ISO 3166-2:MD", countryName: "Moldova (the Republic of)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/Flag_of_Moldova.svg/320px-Flag_of_Moldova.svg.png'},
{id: "ISO 3166-2:MC", countryName: "Monaco", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Flag_of_Monaco.svg/320px-Flag_of_Monaco.svg.png'},
{id: "ISO 3166-2:MN", countryName: "Mongolia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Flag_of_Mongolia.svg/320px-Flag_of_Mongolia.svg.png'},
{id: "ISO 3166-2:ME", countryName: "Montenegro", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/Flag_of_Montenegro.svg/320px-Flag_of_Montenegro.svg.png'},
{id: "ISO 3166-2:MS", countryName: "Montserrat", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Flag_of_Montserrat.svg/320px-Flag_of_Montserrat.svg.png'},
{id: "ISO 3166-2:MA", countryName: "Morocco", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Flag_of_Morocco.svg/320px-Flag_of_Morocco.svg.png'},
{id: "ISO 3166-2:MZ", countryName: "Mozambique", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Flag_of_Mozambique.svg/320px-Flag_of_Mozambique.svg.png'},
{id: "ISO 3166-2:NA", countryName: "Namibia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Flag_of_Namibia.svg/320px-Flag_of_Namibia.svg.png'},
{id: "ISO 3166-2:NR", countryName: "Nauru", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Flag_of_Nauru.svg/320px-Flag_of_Nauru.svg.png'},
{id: "ISO 3166-2:NP", countryName: "Nepal", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9b/Flag_of_Nepal.svg/320px-Flag_of_Nepal.svg.png'},
{id: "ISO 3166-2:NL", countryName: "Netherlands (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Flag_of_the_Netherlands.svg/320px-Flag_of_the_Netherlands.svg.png'},
{id: "ISO 3166-2:NC", countryName: "New Caledonia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Flag_of_FLNKS.svg/320px-Flag_of_FLNKS.svg.png'},
{id: "ISO 3166-2:NZ", countryName: "New Zealand", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/Flag_of_New_Zealand.svg/320px-Flag_of_New_Zealand.svg.png'},
{id: "ISO 3166-2:NI", countryName: "Nicaragua", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Flag_of_Nicaragua.svg/320px-Flag_of_Nicaragua.svg.png'},
{id: "ISO 3166-2:NE", countryName: "Niger (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/Flag_of_Niger.svg/320px-Flag_of_Niger.svg.png'},
{id: "ISO 3166-2:NG", countryName: "Nigeria", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/79/Flag_of_Nigeria.svg/320px-Flag_of_Nigeria.svg.png'},
{id: "ISO 3166-2:NU", countryName: "Niue", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Flag_of_Niue.svg/320px-Flag_of_Niue.svg.png'},
{id: "ISO 3166-2:NF", countryName: "Norfolk Island", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Flag_of_Norfolk_Island.svg/320px-Flag_of_Norfolk_Island.svg.png'},
{id: "ISO 3166-2:MP", countryName: "Northern Mariana Islands (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Flag_of_the_Northern_Mariana_Islands.svg/320px-Flag_of_the_Northern_Mariana_Islands.svg.png'},
{id: "ISO 3166-2:NO", countryName: "Norway", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Norway.svg/320px-Flag_of_Norway.svg.png'},
{id: "ISO 3166-2:OM", countryName: "Oman", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/dd/Flag_of_Oman.svg/320px-Flag_of_Oman.svg.png'},
{id: "ISO 3166-2:PK", countryName: "Pakistan", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/32/Flag_of_Pakistan.svg/320px-Flag_of_Pakistan.svg.png'},
{id: "ISO 3166-2:PW", countryName: "Palau", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Flag_of_Palau.svg/320px-Flag_of_Palau.svg.png'},
{id: "ISO 3166-2:PA", countryName: "Panama", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Flag_of_Panama.svg/320px-Flag_of_Panama.svg.png'},
{id: "ISO 3166-2:PG", countryName: "Papua New Guinea", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e3/Flag_of_Papua_New_Guinea.svg/320px-Flag_of_Papua_New_Guinea.svg.png'},
{id: "ISO 3166-2:PY", countryName: "Paraguay", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/Flag_of_Paraguay.svg/320px-Flag_of_Paraguay.svg.png'},
{id: "ISO 3166-2:PE", countryName: "Peru", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Flag_of_Peru.svg/320px-Flag_of_Peru.svg.png'},
{id: "ISO 3166-2:PH", countryName: "Philippines (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Flag_of_the_Philippines.svg/320px-Flag_of_the_Philippines.svg.png'},
{id: "ISO 3166-2:PL", countryName: "Poland", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/1/12/Flag_of_Poland.svg/320px-Flag_of_Poland.svg.png'},
{id: "ISO 3166-2:PT", countryName: "Portugal", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Portugal.svg/320px-Flag_of_Portugal.svg.png'},
{id: "ISO 3166-2:PR", countryName: "Puerto Rico", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Flag_of_Puerto_Rico.svg/320px-Flag_of_Puerto_Rico.svg.png'},
{id: "ISO 3166-2:QA", countryName: "Qatar", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/Flag_of_Qatar.svg/320px-Flag_of_Qatar.svg.png'},
{id: "ISO 3166-2:RO", countryName: "Romania", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Flag_of_Romania.svg/320px-Flag_of_Romania.svg.png'},
{id: "ISO 3166-2:RW", countryName: "Rwanda", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/Flag_of_Rwanda.svg/320px-Flag_of_Rwanda.svg.png'},
{id: "ISO 3166-2:BL", countryName: "Saint Barthélemy", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Flag_of_Saint_Barth%C3%A9lemy_%28Local%29.svg/320px-Flag_of_Saint_Barth%C3%A9lemy_%28Local%29.svg.png'},
{id: "ISO 3166-2:KN", countryName: "Saint Kitts and Nevis", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg/320px-Flag_of_Saint_Kitts_and_Nevis.svg.png'},
{id: "ISO 3166-2:LC", countryName: "Saint Lucia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Flag_of_Saint_Lucia.svg/320px-Flag_of_Saint_Lucia.svg.png'},
{id: "ISO 3166-2:MF", countryName: "Saint Martin (French part)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Flag_of_France.svg/320px-Flag_of_France.svg.png'},
{id: "ISO 3166-2:PM", countryName: "Saint Pierre and Miquelon", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Flag_of_Saint-Pierre_and_Miquelon.svg/320px-Flag_of_Saint-Pierre_and_Miquelon.svg.png'},
{id: "ISO 3166-2:VC", countryName: "Saint Vincent and the Grenadines", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Flag_of_Saint_Vincent_and_the_Grenadines.svg/320px-Flag_of_Saint_Vincent_and_the_Grenadines.svg.png'},
{id: "ISO 3166-2:WS", countryName: "Samoa", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Flag_of_Samoa.svg/320px-Flag_of_Samoa.svg.png'},
{id: "ISO 3166-2:SM", countryName: "San Marino", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/Flag_of_San_Marino.svg/320px-Flag_of_San_Marino.svg.png'},
{id: "ISO 3166-2:ST", countryName: "Sao Tome and Principe", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Flag_of_Sao_Tome_and_Principe.svg/320px-Flag_of_Sao_Tome_and_Principe.svg.png'},
{id: "ISO 3166-2:SA", countryName: "Saudi Arabia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/Flag_of_Saudi_Arabia.svg/320px-Flag_of_Saudi_Arabia.svg.png'},
{id: "ISO 3166-2:SN", countryName: "Senegal", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Flag_of_Senegal.svg/320px-Flag_of_Senegal.svg.png'},
{id: "ISO 3166-2:RS", countryName: "Serbia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Flag_of_Serbia.svg/320px-Flag_of_Serbia.svg.png'},
{id: "ISO 3166-2:SC", countryName: "Seychelles", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Flag_of_Seychelles.svg/320px-Flag_of_Seychelles.svg.png'},
{id: "ISO 3166-2:SL", countryName: "Sierra Leone", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/Flag_of_Sierra_Leone.svg/320px-Flag_of_Sierra_Leone.svg.png'},
{id: "ISO 3166-2:SG", countryName: "Singapore", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Flag_of_Singapore.svg/320px-Flag_of_Singapore.svg.png'},
{id: "ISO 3166-2:SX", countryName: "Sint Maarten (Dutch part)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Flag_of_Sint_Maarten.svg/320px-Flag_of_Sint_Maarten.svg.png'},
{id: "ISO 3166-2:SK", countryName: "Slovakia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Flag_of_Slovakia.svg/320px-Flag_of_Slovakia.svg.png'},
{id: "ISO 3166-2:SI", countryName: "Slovenia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f0/Flag_of_Slovenia.svg/320px-Flag_of_Slovenia.svg.png'},
{id: "ISO 3166-2:SB", countryName: "Solomon Islands", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Flag_of_the_Solomon_Islands.svg/320px-Flag_of_the_Solomon_Islands.svg.png'},
{id: "ISO 3166-2:SO", countryName: "Somalia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Flag_of_Somalia.svg/320px-Flag_of_Somalia.svg.png'},
{id: "ISO 3166-2:ZA", countryName: "South Africa", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Flag_of_South_Africa.svg/320px-Flag_of_South_Africa.svg.png'},
{id: "ISO 3166-2:GS", countryName: "South Georgia and the South Sandwich Islands", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg/320px-Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg.png'},
{id: "ISO 3166-2:SS", countryName: "South Sudan", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7a/Flag_of_South_Sudan.svg/320px-Flag_of_South_Sudan.svg.png'},
{id: "ISO 3166-2:ES", countryName: "Spain", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/9/9a/Flag_of_Spain.svg/320px-Flag_of_Spain.svg.png'},
{id: "ISO 3166-2:LK", countryName: "Sri Lanka", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Flag_of_Sri_Lanka.svg/320px-Flag_of_Sri_Lanka.svg.png'},
{id: "ISO 3166-2:SD", countryName: "Sudan (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Flag_of_Sudan.svg/320px-Flag_of_Sudan.svg.png'},
{id: "ISO 3166-2:SR", countryName: "Suriname", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Flag_of_Suriname.svg/320px-Flag_of_Suriname.svg.png'},
{id: "ISO 3166-2:SE", countryName: "Sweden", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/4/4c/Flag_of_Sweden.svg/320px-Flag_of_Sweden.svg.png'},
{id: "ISO 3166-2:CH", countryName: "Switzerland", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Switzerland.svg/24px-Flag_of_Switzerland.svg.png'},
{id: "ISO 3166-2:TJ", countryName: "Tajikistan", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Flag_of_Tajikistan.svg/320px-Flag_of_Tajikistan.svg.png'},
{id: "ISO 3166-2:TH", countryName: "Thailand", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_Thailand.svg/320px-Flag_of_Thailand.svg.png'},
{id: "ISO 3166-2:TG", countryName: "Togo", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Flag_of_Togo.svg/320px-Flag_of_Togo.svg.png'},
{id: "ISO 3166-2:TK", countryName: "Tokelau", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Flag_of_Tokelau.svg/320px-Flag_of_Tokelau.svg.png'},
{id: "ISO 3166-2:TO", countryName: "Tonga", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Tonga.svg/320px-Flag_of_Tonga.svg.png'},
{id: "ISO 3166-2:TT", countryName: "Trinidad and Tobago", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/Flag_of_Trinidad_and_Tobago.svg/320px-Flag_of_Trinidad_and_Tobago.svg.png'},
{id: "ISO 3166-2:TN", countryName: "Tunisia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Flag_of_Tunisia.svg/320px-Flag_of_Tunisia.svg.png'},
{id: "ISO 3166-2:TR", countryName: "Turkey", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Flag_of_Turkey.svg/320px-Flag_of_Turkey.svg.png'},
{id: "ISO 3166-2:TM", countryName: "Turkmenistan", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Flag_of_Turkmenistan.svg/320px-Flag_of_Turkmenistan.svg.png'},
{id: "ISO 3166-2:TC", countryName: "Turks and Caicos Islands (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Flag_of_the_Turks_and_Caicos_Islands.svg/320px-Flag_of_the_Turks_and_Caicos_Islands.svg.png'},
{id: "ISO 3166-2:TV", countryName: "Tuvalu", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Flag_of_Tuvalu.svg/320px-Flag_of_Tuvalu.svg.png'},
{id: "ISO 3166-2:UG", countryName: "Uganda", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Flag_of_Uganda.svg/320px-Flag_of_Uganda.svg.png'},
{id: "ISO 3166-2:UA", countryName: "Ukraine", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Ukraine.svg/320px-Flag_of_Ukraine.svg.png'},
{id: "ISO 3166-2:AE", countryName: "United Arab Emirates (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Flag_of_the_United_Arab_Emirates.svg/320px-Flag_of_the_United_Arab_Emirates.svg.png'},
{id: "ISO 3166-2:GB", countryName: "United Kingdom of Great Britain and Northern Ireland (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/320px-Flag_of_the_United_Kingdom.svg.png'},
{id: "ISO 3166-2:US", countryName: "United States of America (the)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/320px-Flag_of_the_United_States.svg.png'},
{id: "ISO 3166-2:UY", countryName: "Uruguay", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Flag_of_Uruguay.svg/320px-Flag_of_Uruguay.svg.png'},
{id: "ISO 3166-2:UZ", countryName: "Uzbekistan", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Flag_of_Uzbekistan.svg/320px-Flag_of_Uzbekistan.svg.png'},
{id: "ISO 3166-2:VU", countryName: "Vanuatu", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Vanuatu.svg/320px-Flag_of_Vanuatu.svg.png'},
{id: "ISO 3166-2:VE", countryName: "Venezuela (Bolivarian Republic of)", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Flag_of_Venezuela_%28state%29.svg/320px-Flag_of_Venezuela_%28state%29.svg.png'},
{id: "ISO 3166-2:WF", countryName: "Wallis and Futuna", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/Flag_of_Wallis_and_Futuna.svg/320px-Flag_of_Wallis_and_Futuna.svg.png'},
{id: "ISO 3166-2:YE", countryName: "Yemen", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Flag_of_Yemen.svg/320px-Flag_of_Yemen.svg.png'},
{id: "ISO 3166-2:ZM", countryName: "Zambia", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Flag_of_Zambia.svg/320px-Flag_of_Zambia.svg.png'},
{id: "ISO 3166-2:ZW", countryName: "Zimbabwe", countryCapital: "",countryFlagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/Flag_of_Zimbabwe.svg/320px-Flag_of_Zimbabwe.svg.png'},
]; 

export default countryData;