import { Grid } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import '../App.css';
import { updateUsername } from "../configandstores/Users";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../configandstores/firebase";
import useStyles from '../configandstores/styles';
import "./Login.css";
import BackgroundSlider from 'react-background-slider'
import image1 from '../images/background/1.jpg'
import image2 from '../images/background/2.jpg'
import image3 from '../images/background/3.jpg'
import image4 from '../images/background/4.jpg'

const SignIn = () => {  
  const classes=useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  ////////////////////////////////
  const [emailNew, setEmailNew] = useState("");
  const [passwordNew, setPasswordNew] = useState("");
  const [userNew, loading, error] = useAuthState(auth);
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (userNew) {
      dispatch(
        updateUsername({ username: userNew.displayName })
      );
      navigate('Home');
    }
  }, [userNew, loading]);

  //////////////////////////////

  return (

    <>
     <BackgroundSlider images={[image1, image2, image3,image4]} duration={10} transition={2} /> 
    <Grid container justifyContent="center">
    <Grid item>


    <div className="login">
      <div className="login__container">
        <input
          type="text"
          className="login__textBox"
          value={emailNew}
          onChange={(e) => setEmailNew(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="login__textBox"
          value={passwordNew}
          onChange={(e) => setPasswordNew(e.target.value)}
          placeholder="Password"
        />
        <button
          className="login__btn"
          onClick={() => logInWithEmailAndPassword(emailNew, passwordNew)}
        >
          Login
        </button>
        <button className="login__btn login__google" onClick={signInWithGoogle}>
          Login with Google
        </button>
        <div>
          <Link to="/reset">Forgot Password</Link>
        </div>
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>
      </div>
    </div>
    </Grid>
    </Grid>
    </>
  );
  };

export default SignIn;
