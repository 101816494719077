import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useStyles from '../configandstores/styles';
import categoryData from '../data/categoryData';
import { setCategory, setGradeLevel } from "../configandstores/Users";

export default function ProfileTiles({filterValue}) {

//////////////////////////////////////
const dispatch = useDispatch();
const user = useSelector((state) => state.users.value);
const [newUsername, setNewUsername] = useState("");
//////////////////////////////////////////
  const classes = useStyles();
  const navigate = useNavigate();
  const routeChange = (label) =>{ 
    let path = `/NewQuestions`; 
    dispatch(setCategory({ category: label}));
    dispatch(setGradeLevel({ gradeLevel: "Grade3 (7-8 yrs)"}));
    navigate(path);
  }
  return (
    <React.Fragment>
        <div className={classes.heroContent}>
        <Typography variant="h4" align="left" color="textPrimary" >
            {filterValue}
        </Typography>
        </div>
        <Grid container spacing={5}>
            {categoryData.filter(name => name.label.split(":")[0]===filterValue).map((card) => (
              <Grid item key={card} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={require('../images/questionTileShapes/'+card.img+'.png')}
                    // "https://source.unsplash.com/random"
                    title="Image title"
                    onClick={() => {
                      routeChange(card.label)
                    }}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                    {card.label.split(":")[1]}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <button 
                    onClick={() => {
                      routeChange(card.label)
                    }}
                    >
                      View</button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
            </Grid>
    </React.Fragment>
  );
}