import { Container, CssBaseline, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { HashRouter } from "react-router-dom";
import Link from '@material-ui/core/Link';
import Tab from '@material-ui/core/Tab';
import { useEffect} from "react";
import './App.css';
import useStyles from './configandstores/styles';
import Views from './corecomponents/Views';
import BackgroundSlider from 'react-background-slider'
import image1 from './images/background/1.jpg'
import image2 from './images/background/2.jpg'
import image3 from './images/background/3.jpg'
import image4 from './images/background/4.jpg'

export const UserContext = React.createContext();

//Borrowed heavily for design from: https://www.youtube.com/watch?v=Xoz31I1FuiY
// for cloudinary upload from: https://www.youtube.com/watch?v=Y-VgaRwWS3o
// for mongodb upload from: 

function Copyright() {
  return (
    <Typography variant="subtitle1" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit">
        theBrainMint
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
function Patron() {
  return (
    <Typography variant="subtitle1" color="textSecondary" align="center">
      <Link color="inherit" href='https://www.patreon.com/bePatron?u=73240022'>
        Support us @Patreon
      </Link>{' '}
    </Typography>
  );
}

function App() {
  const classes=useStyles();
  const [user, setUser] = useState({ loggedIn: false,username: '' });
  useEffect(() => {
    document.title = "theBrainMint"
 }, []);
  return (
    <>
  <CssBaseline />
    <main>
      <div className={classes.container}>
    <BackgroundSlider images={[image1, image2, image3,image4]} duration={10} transition={2} /> 
        <Container>
        <HashRouter>
          <UserContext.Provider value={{ user, setUser }}>
              <Views />
      {/* <Tab display="flex" alignItems='center' justifyContent='center' label='Become a Patron!' variant="body2" color="textSecondary" to='https://www.patreon.com/bePatron?u=73240022' component={Link} />     */}
          </UserContext.Provider>
      </HashRouter>
        </Container>
      </div>
    </main>
    <footer className={classes.footer}>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          All Rights Reserved. 
        </Typography>
        <Copyright />
        <Patron />
      </footer>
  </>

  );
}

export default App;
