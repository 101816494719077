import { Box, FormControl, Grid, Input, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { default as React} from 'react';
import '../App.css';
import useStyles from '../configandstores/styles';
import './QuestionGrid';
import categoryData from '../data/categoryData'
import gradeLevelData from '../data/gradeLevelData'

export default function Tags({category, setCategory,gradeLevel, setGradeLevel, setCustomTag}) {
    const classes=useStyles();  
    return (
        <Grid container className={classes.cardContent} spacing={1} justifyContent="center">
        <Box display="flex" >
          <Grid item>        
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={category}
                  onChange={(event)=>{setCategory(event.target.value);}}
                >
                  {categoryData.map((data) => (
                    <MenuItem key={data.id} value={data.label}>
                      {data.label}
                    </MenuItem>
                  ))}
                </Select>
            </FormControl>       
          </Grid>
          <Grid item> 
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Grade level</InputLabel>
              <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={gradeLevel}
                  onChange={(event)=>{setGradeLevel(event.target.value);}}
                >
                  {gradeLevelData.map((data) => (
                    <MenuItem key={data.id} value={data.label}>
                      {data.label}
                    </MenuItem>
                  ))}
                </Select>
            </FormControl>  
          </Grid>
          <Grid item>
            <Typography className={classes.cardContent}>Please enter comma separated custom tags:-</Typography>
            <Input className={classes.cardContent} type='text' placeholder='e.g. difficult, easy, fun ' onChange={(event)=>{setCustomTag(event.target.value);}} />
          </Grid>          
        </Box>
        </Grid>
    )
}

