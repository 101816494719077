import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation } from "react-router";
import { Navigate, Outlet } from "react-router-dom";
import { auth } from "../configandstores/firebase";

const ProtectedRoutes = () => {
  const [user, loading, error] = useAuthState(auth);
  const location = useLocation();
  const isAuth = user;

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace state={{ from: location }} />
  );
};

export default ProtectedRoutes;
