import {makeStyles} from '@material-ui/core/styles'
import { red, green, blue } from '@material-ui/core/colors';
//taylor-wilcox-NTur2_QKpg0-unsplash
const useStyles = makeStyles((theme)=>({
  container:{
    maxWidth:('xl'),
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: green[500],
    },
    padding: theme.spacing(3,5),
    backgroundColor: theme.palette.background.paper,
    justifyContent:"center",
  },
  appbar:{
    width: 'calc(100% - ${}px)',
    elevation :5,
    position: 'relative'
  } ,
  toolbar:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent:'space-between'
  } ,
  toolbarlinks:{
    color: 'white',
    marginRight: 10,
  },
  icon:{
        marginRight:theme.spacing(1),
  },
  question:{
        marginTop:theme.spacing(5)
  },
  questioncard:{
    display: 'flex',
    flexDirection:'column',
    marginTop:theme.spacing(5), 
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 0, 0),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    flexDirection: 'row',
  },
  cardContent: {
    flexGrow: 1,
    marginTop:30
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default useStyles;