import * as React from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import { useAuthState } from "react-firebase-hooks/auth";
import { logout,auth } from "../configandstores/firebase";
import useStyles from '../configandstores/styles';

const PageLinks = () => {
  const user = useSelector((state) => state.users.value);
  const [userNew, loading, error] = useAuthState(auth);
  const classes=useStyles();
  return (
    <div>
      <Link to="/Home" className={classes.toolbarlinks}> Home </Link>
      <Link to="/Profile" className={classes.toolbarlinks}> Profile </Link>
      {/* <Link to="/ShowQuestions" className={classes.toolbarlinks}> Questions </Link>
      <Link to="/NewQuestions" className={classes.toolbarlinks}> NewQuestions </Link> */}
      <Link to="/Creator" className={classes.toolbarlinks}> Create </Link>
      <Link to="/Aboutus" className={classes.toolbarlinks}> About Us </Link>
      <Typography className={classes.toolbarlinks} > Hi {user.username}!</Typography>
      <img className={classes.toolbarlinks} src={userNew.photoURL} alt="" height="25" width="25" />
      <button onClick={logout}>Logout</button>
    </div>
  );
};

export default PageLinks;
