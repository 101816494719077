import { AppBar, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { Outlet } from 'react-router';
import '../App.css';
import useStyles from '../configandstores/styles';
import PageLinks from './PageLinks';
import '../contentcreator/QuestionGrid';

export default function WithNav(){
  const classes=useStyles();

  return (
    <>
      <AppBar position="relative" className={classes.Appbar}>
      <Toolbar className={classes.Toolbar}>
        <Typography variant="h6">
          theBrainMint
        <PageLinks />
        </Typography>
        
      </Toolbar>
    </AppBar>  
      <Outlet />
    </>
  );
};