import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Profile from "./Profile";
import Aboutus from "./Aboutus";
// import Account from "./components/Account";
import ShowQuestions from "../deprecated/ShowQuestions";
import NewQuestions from "../quizmain/NewQuestions";
import Creator from "../contentcreator/Creator";
// import Format from "./components/Format";
import SignIn from "../loginandregister/SignIn";
import Register from "../loginandregister/Register";
import WithNav from "./WithNav";
import WithoutNav from "./WithoutNav";

import ProtectedRoutes from "./ProtectedRoutes";
import ReactGA from 'react-ga';


const Views = () => {
  ReactGA.initialize("UA-226968170-1");
  ReactGA.pageview("/");
  ReactGA.pageview("/Home");
  ReactGA.pageview("/Profile");
  ReactGA.pageview("/Aboutus");
  ReactGA.pageview("/ShowQuestions");
  ReactGA.pageview("/NewQuestions");

  return (   
    <Routes>
    <Route element={<WithoutNav />}>
      <Route path="/" element={<SignIn />} />
      <Route path="/register" element={<Register />} />
    </Route>
    <Route element={<ProtectedRoutes />}>
      <Route element={<WithNav />}>      
        <Route path="/Home" element={<Home />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Aboutus" element={<Aboutus />} />
        <Route path="/ShowQuestions" element={<ShowQuestions />} />
        <Route path="/NewQuestions" element={<NewQuestions />} />
        <Route path="/Creator" element={<Creator />} />
      </Route>
    </Route>
  </Routes>
  );
};

export default Views;