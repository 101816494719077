import { randomNumberGenerator} from './Helper';
import countryData from '../data/countryData'

function getRandomItem(set) {
    let items = Array.from(set);
    return items[Math.floor(Math.random() * items.length)];
}

export function getSpotFlagQuestions(category_def){
    const questionArray = [];

	const numberOfCountries=countryData.length
	const indexes_without_replacement = new Set(Array.from(Array(numberOfCountries).keys()))
	//Create set of all possible indexes
	//https://stackoverflow.com/questions/42739256/how-get-random-item-from-es6-map-or-set

			for (let i=0;i<10;i++){
				var question = new Object();
				const chosenCountryIndex = getRandomItem(indexes_without_replacement)
				// remove this index from set for producing wrong answer choices
				indexes_without_replacement.delete(chosenCountryIndex)
				question.questionText=`Which country does this flag belong to?`
				question.questionPicUrl=countryData[chosenCountryIndex].countryFlagUrl;
				var answerOptions= [];
				var correctChoiceVal=countryData[chosenCountryIndex].countryName
				var correctOption=randomNumberGenerator(0,3)
				for (let i=0;i<4;i++){
					var option = new Object();
					if (i==correctOption){
						option.answerText=correctChoiceVal
						option.isCorrect=true
					} else {
						//Create a wrong answer for other choices
						var wrongChoiceIndex=getRandomItem(indexes_without_replacement)
						indexes_without_replacement.delete(wrongChoiceIndex)
						//TODO: In order to make sure flags don't repeat, there should be a randomChoiceFromSet function, where the set is update to keep only remaining flags
						//if (wrongChoiceIndex==correctChoice){wrongChoiceIndex+=1}
						option.answerText=countryData[wrongChoiceIndex].countryName
						option.isCorrect=false
					}
					option.answerPicUrl=i
					answerOptions.push(option)
				}
				var finalComputedPacket = new Object();
				finalComputedPacket.question=question;
				finalComputedPacket.answerOptions=answerOptions;	
				questionArray.push(finalComputedPacket)
			}
    return questionArray;
}