import Grid from '@material-ui/core/Grid';
import React from 'react';

export default function Aboutus(){

  return (
    <>
      <h1 style={{ marginLeft: 60, fontWeight: 400, color: "#555" }}>
        Welcome!
      </h1>
      <h2 style={{ marginLeft: 60, fontWeight: 400, color: "#555" }}>
        Me and my cofounder are parents just like you. We have a knack for technology and our core belief is that education of our children is fundamental to our future.
      </h2>
      <h2 style={{ marginLeft: 60, fontWeight: 400, color: "#555" }}>
        We believe most of the elementary learning happens offine, with the child interacting with real world and away from device screens. 
        Keeping that in mind, we've created small test batteries that would not take more than 5-10 minutes per day. 
        This would help assess their progress, strengths and areas of improvement.
        
      </h2>
      <h2 style={{ marginLeft: 60, fontWeight: 400, color: "#555" }}>
        We hope you find this useful and wish you and your child the very best.
      </h2>
      <h2 style={{ marginLeft: 60, fontWeight: 400, color: "#555" }}>
        We are still Young and we look forward to growing with you.
      </h2>

      <Grid container spacing={5}>
        <Grid item xs={12} sm={6} md={4}>
          
        </Grid>
      </Grid>
    </>
  );
};
