import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import tik from '../images/tik.png'
import wrong from '../images/wrong.png'
// import rows from "./rowsData";

let id = 0;
const createData = (name, calories, fat, carbs, protein) => {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
};
const data = [
    createData("Frozen yoghurt sdafdfdssfdfsdsdsfdsfdsfsfdfsd sd fsdfsdfsdsfdsfd",159,6.0,24,4.0),
    createData(2,"Ice cream sandwichdsfsdfsdf dfssdfsdafsad asffsd asfds df",237,9.0,37,4.3)
]
export default function({questions, times, selectedOption}) {
    return (  
  <div>
      
      <h1 className="title">Results</h1>

      <Table>
        <TableHead>
          <TableRow>
          <TableCell >Question</TableCell>
          <TableCell>Option1</TableCell>
          <TableCell>Option2</TableCell>
          <TableCell>Option3</TableCell>
          <TableCell>Option4</TableCell>
          <TableCell>Time Taken</TableCell>
          <TableCell>Suggested Reading</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questions.map((q, index) => (
            <TableRow key={q} hover={true}>
              <TableCell>{q.question.questionText} <img width='100%' src={q.question.questionPicUrl<1000?"":q.question.questionPicUrl} /></TableCell>
              <TableCell>
                {q.answerOptions[0].answerText} 
                <img width='100%' src={q.answerOptions[0].answerPicUrl<1000?"":q.answerOptions[0].answerPicUrl} />
                <img width='100%' src={q.answerOptions[0].isCorrect==true?tik:""} />
                <img width='100%' src={(selectedOption[index]==0 & q.answerOptions[0].isCorrect!=true)?wrong:""} />
              </TableCell>
              <TableCell>
                {q.answerOptions[1].answerText} 
                <img width='100%' src={q.answerOptions[1].answerPicUrl<1000?"":q.answerOptions[1].answerPicUrl} />
                <img width='100%' src={q.answerOptions[1].isCorrect==true?tik:""} />
                <img width='100%' src={(selectedOption[index]==1 & q.answerOptions[1].isCorrect!=true)?wrong:""} />
              </TableCell>
              <TableCell>
                {q.answerOptions[2].answerText} 
                <img width='100%' src={q.answerOptions[2].answerPicUrl<1000?"":q.answerOptions[2].answerPicUrl} />
                <img width='100%' src={q.answerOptions[2].isCorrect==true?tik:""} />
                <img width='100%' src={(selectedOption[index]==2 & q.answerOptions[2].isCorrect!=true)?wrong:""} />
                </TableCell>
              <TableCell>
                {q.answerOptions[3].answerText} 
                <img width='100%' src={q.answerOptions[3].answerPicUrl<1000?"":q.answerOptions[3].answerPicUrl} />
                <img width='100%' src={q.answerOptions[3].isCorrect==true?tik:""} />
                <img width='100%' src={(selectedOption[index]==3 & q.answerOptions[3].isCorrect!=true)?wrong:""} />
                </TableCell>
              <TableCell>
                {Math.round((times[index+1])/1000,2)} seconds
                </TableCell>
              <TableCell>
                {q.suggestedReading}
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
  </div>
);
}