import { randomNumberGenerator, math_it_up} from './Helper';

export function getSpotMathQuestions(category_def){
    const questionArray = [];
			for (let i=0;i<10;i++){
				var question = new Object();
				var operand1= randomNumberGenerator(category_def.operand1.low,category_def.operand1.high)
				var operand2= randomNumberGenerator(category_def.operand2.low,category_def.operand2.high)
				var operator=category_def.operator
				// Condition below is added so that differences are not negative
				if (operand2>operand1 & operator=='-'){
					[operand1,operand2]=[operand2,operand1]
				}
				question.questionText=`What's ${operand1} ${operator} ${operand2}?`
				question.questionPicUrl=0;
				var answerOptions= [];
				var correctChoice = randomNumberGenerator(0,3)
				var correctChoiceVal=math_it_up[operator](operand1, operand2)
				for (let i=0;i<4;i++){
					var option = new Object();
					if (i==correctChoice){
						option.answerText=correctChoiceVal
						option.isCorrect=true
					} else {
						//Create a wrong answer for other choices
						option.answerText=correctChoiceVal+randomNumberGenerator(1,10)
						option.isCorrect=false
					}
					option.answerPicUrl=i
					answerOptions.push(option)
				}
				var finalComputedPacket = new Object();
				finalComputedPacket.question=question;
				finalComputedPacket.answerOptions=answerOptions;	
				questionArray.push(finalComputedPacket)
			}
    return questionArray;
}