// WithoutNav.js (Stand-alone Functional Component)
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { Outlet } from 'react-router';
import '../App.css';
import useStyles from '../configandstores/styles';
import '../contentcreator/QuestionGrid';
import logo from '../images/logo.PNG';
import BackgroundSlider from 'react-background-slider'
import image1 from '../images/background/1.jpg'
import image2 from '../images/background/2.jpg'
import image3 from '../images/background/3.jpg'
import image4 from '../images/background/4.jpg'

export default function WithNav(){
    const classes=useStyles();
    return (
        <>
        <AppBar position="relative">
        <Toolbar>
          <img  className={classes.icon} src={logo} alt="" height="40" width="40" />
          <Typography variant="h6">
          theBrainMint
          </Typography>
        </Toolbar>
        </AppBar>
        <BackgroundSlider images={[image1, image2, image3,image4]} duration={10} transition={2} /> 

        <Outlet />
        </>

    )
} 
